import React from "react"

export const ModulePlaceholder = ({ type = "tabgroup", cssClasses = "" }) => {
  return (
    <section
      className={`module-placeholder px-4 py-20 bg-gray-100 ${cssClasses}`}
    >
      <div className="max-w-5xl px-4 py-10 mx-auto text-center bg-white shadow-xl">
        <div className="text-sm md:text-base lg:text-xl">
          <span
            role="img"
            alt="emoji"
            aria-label="emoji"
            className="hidden mr-2 text-lg lg:text-2xl md:inline-block"
          >
            🚧
          </span>{" "}
          The {type} module is still being built{" "}
          <span
            role="img"
            alt="emoji"
            aria-label="emoji"
            className="inline-block ml-2 text-lg lg:text-2xl"
          >
            🚧
          </span>{" "}
        </div>
      </div>
    </section>
  )
}
