import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Imgix } from "@elements"
import { Link } from "@elements"

export const HowCanWeHelp = ({ htmlId, cssClasses }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          API_URL
        }
      }
      wpgraphql {
        optionsTheme {
          optionsHowCanWeHelpYou {
            linkBlocksModuleTitle
            linkBlocks {
              linkBlockIcon {
                mediaItemUrl
                altText
              }
              linkBlockTitle
              linkBlockContent
              linkBlockUrl
            }
          }
        }
      }
    }
  `)

  const blocks = data.wpgraphql.optionsTheme.optionsHowCanWeHelpYou.linkBlocks
  const title =
    data.wpgraphql.optionsTheme.optionsHowCanWeHelpYou.linkBlocksModuleTitle

  return (
    <section
      id={htmlId}
      className={`module-how-can-we-help relative z-20 mt-10 -mb-5 lg:mt-16 sm:-mb-10 xl:-mb-16 ${cssClasses}`}
    >
      <div className="container px-4">
        <h2 className="text-3xl font-light tracking-tight text-center text-gray-400 sm:text-4xl lg:text-5xl font-display">
          {title}
        </h2>
        {/* grid start */}
        <div className="flex flex-wrap max-w-sm mx-auto mt-4 sm:-mx-2 sm:max-w-3xl md:mx-auto lg:max-w-6xl xl:max-w-6xl">
          {blocks.map((block, index) => (
            <div
              className="w-full mt-4 sm:w-1/2 lg:w-1/3 xl:w-1/3 sm:px-2"
              key={index}
            >
              {/* card start */}
              <Link
                to={`${block.linkBlockUrl.replace(
                  data.site.siteMetadata.API_URL,
                  ""
                )}`}
              >
                <div className="h-full px-8 py-12 transition duration-150 transform bg-white shadow-xl cursor-pointer how-can-we-help-you-card lg:px-10 lg:py-10 xl:py-16 hover:-translate-y-1">
                  <div className="relative z-20">
                    <div className="flex items-center h-24">
                      <Imgix
                        width={100}
                        src={`${block.linkBlockIcon.mediaItemUrl}`}
                        alt={`${block.linkBlockIcon.altText}`}
                        className="transition duration-150"
                      />
                    </div>
                    <h3 className="mt-8 text-xl font-medium text-gray-400 transition duration-150 font-display">
                      {block.linkBlockTitle}
                    </h3>
                    <p className="mt-4 text-sm leading-relaxed tracking-wide text-gray-200 transition duration-150">
                      {block.linkBlockContent}
                    </p>
                  </div>
                </div>
              </Link>
              {/* card end */}
            </div>
          ))}
        </div>
        {/* grid end */}
      </div>
    </section>
  )
}
