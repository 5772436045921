import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "@elements"

function ChevronRight({
  className = "w-5 h-5",
  color = "white",
  width = 22,
  height = 35,
  viewbox = "0 0 22 35",
  fill = "none",
}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox={viewbox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 5L17.6484 17.6484L5 30.2969"
        stroke={color}
        stroke-width="6"
        stroke-linecap="square"
      />
    </svg>
  )
}

export const BlogCategoryNavigation = () => {
  const data = useStaticQuery(graphql`
    query {
      wpgraphql {
        blogCategory: category(id: "blog", idType: SLUG) {
          id
          name
          slug
          uri
          children {
            nodes {
              id
              name
              uri
              slug
            }
          }
        }
      }
    }
  `)
  const blogCategory = data.wpgraphql.blogCategory
  return (
    <div className="w-full bg-white shadow-2xl">
      <h3 className="bg-teal-500 text-white text-xl py-2 px-6 block font-bold">
        Categories
      </h3>
      <ul className="px-4">
        {blogCategory.children.nodes.map((category, idx, arr) => (
          <li key={category.id}>
            <Link
              to={`${category.uri}/`}
              className={`py-4 px-2 text-xl text-teal-500 ${
                idx !== arr.length - 1 ? "border-b" : ""
              } border-gray-100 relative flex items-center justify-between`}
            >
              {category.name} <ChevronRight color="#3e77b2" />
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}
