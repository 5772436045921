import React from "react"
import { Link } from "@elements"

export const CtaBillboard = ({
  cssClasses,
  headline,
  content,
  alignment: textAlign,
  callToActionGroup,
  ctaBillboardSecondCallToActionCallToActionGroup,
  backgroundColor: bgColor,
  htmlId,
}) => {
  const backgroundColor =
    bgColor === "white"
      ? `bg-white`
      : bgColor === "light gray" || bgColor === "Light Gray"
      ? `bg-offwhite`
      : bgColor === "gradient" || bgColor === "Gradient"
      ? `bg-gradient-brand text-white`
      : `bg-transparent`
  const alignment = textAlign.toLowerCase()
  return (
    <section
      id={htmlId}
      className={`billboard-cta px-4 py-16 text-white md:py-20 lg:py-24 text-${alignment} ${backgroundColor} ${cssClasses}`}
    >
      <div className="container">
        <h2 className="text-3xl font-light leading-tight tracking-tight sm:text-4xl lg:text-5xl font-display">
          {headline}
        </h2>
        <div
          dangerouslySetInnerHTML={{ __html: content }}
          className="mt-4"
        ></div>
        <div>
          {callToActionGroup.ctaLinkText && (
            <Link
              to={callToActionGroup.ctaLinkUrl}
              className={`mt-8 sm:mr-6 btn w-full sm:w-auto text-center ${callToActionGroup.ctaButtonType}`}
            >
              {callToActionGroup.ctaLinkText}
            </Link>
          )}
          {ctaBillboardSecondCallToActionCallToActionGroup.ctaLinkText && (
            <Link
              to={ctaBillboardSecondCallToActionCallToActionGroup.ctaLinkUrl}
              className={`mt-8 mr-6 btn outline-white w-full sm:w-auto text-center`}
            >
              {ctaBillboardSecondCallToActionCallToActionGroup.ctaLinkText}
            </Link>
          )}
        </div>
      </div>
    </section>
  )
}
