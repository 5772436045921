import React from "react"

export const SectionIntro = ({
  introTitle,
  introBackgroundColor: bgColor,
  cssClasses,
  htmlId,
}) => {
  const backgroundColor =
    bgColor === "white" || "White"
      ? `bg-white`
      : bgColor === "light gray" || "Light Gray"
      ? `bg-offwhite`
      : `bg-transparent`
  return (
    <section
      id={htmlId}
      className={`module-section-intro p-12 pt-40 relative z-10 -mt-20 ${backgroundColor} ${cssClasses}`}
    >
      <h2 className={`text-4xl xl:text-5xl text-center font-light`}>
        {introTitle}
      </h2>
    </section>
  )
}
