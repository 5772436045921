import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { CardText } from "@elements"
import { htmlDecode } from "@utils"

export const Tips = ({ cssClasses, htmlId }) => {
  const data = useStaticQuery(graphql`
    query {
      wpgraphql {
        tips(first: 1000) {
          edges {
            node {
              id
              uri
              title
              content
            }
          }
        }
      }
    }
  `)

  const tips = data.wpgraphql.tips.edges

  return (
    <section id={htmlId} className={`px-4 py-10 ${cssClasses}`}>
      <div className="container">
        <div className="flex flex-wrap lg:-mx-2 lg:justify-start">
          {tips.map(({ node }) => {
            return (
              <div
                key={node.id}
                className="w-full max-w-lg mx-auto mt-6 lg:w-1/3 lg:mt-0 lg:mb-6 lg:px-2 lg:mx-0 lg:max-w-md"
              >
                <CardText
                  title={htmlDecode(node.title)}
                  content={htmlDecode(node.content)}
                  image="https://rum.imgix.net/wp-content/uploads/2020/06/02033352/placeholder.png"
                  linkUrl={node.uri}
                />
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}
