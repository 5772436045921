import React, { useState, useEffect } from "react"
import { motion } from "framer-motion"
import { Imgix, Link } from "@elements"
import { htmlDecode } from "@utils"

export const Testimonial = props => {
  const contentOpacity = props.opaqueContent ? `opacity-50` : `opacity-100`
  // use image placement to determine which way to animate testimonial entry
  const enterXFrom = props.animationDirection === "Right" ? -100 : 100
  const [testimonial, setTestimonial] = useState(props)

  useEffect(() => {
    setTestimonial(props)
  }, [props])

  return (
    <motion.article
      key={testimonial.reviewerName}
      initial={{
        opacity: props.opaqueContent ? 1 : 0,
        x: props.opaqueContent ? 0 : enterXFrom,
      }}
      animate={{
        opacity: props.opaqueContent ? 1 : 1,
        x: 0,
      }}
      transition={{ ease: "easeInOut" }}
      className="testimonial relative z-10 p-8 bg-white shadow-xl lg:px-10"
    >
      <Link
        to={`/testimonials#${testimonial.slug}`}
        title="Read full testimonial"
        className="flex flex-col block"
      >
        <span className="absolute testimonial-read-more hidden sm:block right-0 bottom-0 px-8 py-8 font-medium">
          view more »
        </span>
        <p
          className={`text-gray-400 lg:text-lg font-display font-light ${contentOpacity}`}
        >
          {htmlDecode(testimonial.content)}
        </p>
        <footer className="flex items-center mt-4">
          {testimonial.reviewerPhoto && (
            <div className="flex-none w-12 h-12 mr-4 overflow-hidden rounded-full">
              <Imgix
                width={48}
                height={48}
                src={`${testimonial.reviewerPhoto}`}
                alt={`Review photo`}
                className={`object-cover w-full h-full ${testimonial.contentOpacity}`}
              />
            </div>
          )}
          {testimonial.reviewerName && (
            <span
              className={`inline-block font-semibold leading-tight uppercase font-display ${testimonial.contentOpacity}`}
            >
              {`${testimonial.reviewerName}`}
            </span>
          )}
        </footer>
      </Link>
    </motion.article>
  )
}
