import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

export const SEO = ({ description, lang, meta, title, image: metaImage }) => {
  const { site, wpgraphql, thumbnail } = useStaticQuery(
    graphql`
      query {
        wpgraphql {
          ...SiteSettings
        }
        thumbnail: file(relativePath: { eq: "logos/logo-large.jpg" }) {
          childImageSharp {
            original {
              src
              height
              width
            }
          }
        }
        site {
          siteMetadata {
            title
            description
            author
            PRIME_URL
          }
        }
      }
    `
  )

  const metaDescription = description || wpgraphql.generalSettings.description
  const image =
    metaImage && metaImage.sourceUrl
      ? `${metaImage.sourceUrl}`
      : metaImage && metaImage.src
      ? `${site.siteMetadata.PRIME_URL}${metaImage.src}`
      : `${site.siteMetadata.PRIME_URL}${thumbnail.childImageSharp.original.src}`

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${wpgraphql.generalSettings.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url,`,
          content: `https://royalunitedmortgage.com`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          metaImage
            ? [
                {
                  property: "og:image",
                  content: image,
                },
                {
                  property: "og:image:width",
                  content: metaImage.width,
                },
                {
                  property: "og:image:height",
                  content: metaImage.height,
                },
                {
                  name: "twitter:card",
                  content: "summary_large_image",
                },
              ]
            : [
                {
                  name: "twitter:card",
                  content: "summary",
                },
              ]
        )
        .concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}
