import { graphql, useStaticQuery } from "gatsby"

export const useGlobalModules = () => {
  const { wpgraphql } = useStaticQuery(
    graphql`
      query {
        wpgraphql {
          ...OptionsTheme
        }
      }
    `
  )

  return {
    howWeWork: wpgraphql.optionsTheme.optionsHowWeWork,
    howCanWeHelpYou: wpgraphql.optionsTheme.optionsHowCanWeHelpYou,
    whatAreYouLookingFor: wpgraphql.optionsTheme.optionsWhatAreYouLookingFor,
  }
}
