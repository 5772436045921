import React from "react"
import { Form as FormElement } from "@elements"
import { useGravityForms } from "@hooks"

export const Form = ({ formId, cssClasses, htmlId }) => {
  const form = useGravityForms(formId)
  console.log("In shared form")
  return (
    <section
      id={htmlId}
      className={`module-form flex flex-wrap bg-white pt-20 ${cssClasses}`}
    >
      <div className="container px-4">
        <div className="w-full lg:w-1/2">
          {form.title && (
            <h1 className="mb-8 text-2xl font-light leading-tight tracking-tight sm:text-3xl lg:text-5xl font-display">
              {form.title}
            </h1>
          )}
          {form.description && (
            <p className="text-gray-300 mt-2 mb-4 text-base leading-relaxed;">
              {form.description}
            </p>
          )}
          <FormElement form={form} />
        </div>
      </div>
    </section>
  )
}
