import React from "react"
import Imgix from "react-imgix"

export const SocialProof = ({
  headline,
  logos,
  subheading,
  wysiwyg,
  cssClasses,
  htmlId,
}) => {
  return (
    <section
      id={htmlId}
      className={`module-social-proof py-20 mt-16 text-center ${cssClasses}`}
    >
      <div className="container">
        <h2 className="text-3xl font-light leading-tight text-gray-400 md:text-4xl xl:text-5xl font-display">
          {headline}
        </h2>
        <h3>{subheading}</h3>
        <div
          className="mt-4 wysiwyg"
          dangerouslySetInnerHTML={{ __html: wysiwyg }}
        ></div>
        <div className="flex lg:mt-6 flex-wrap justify-center mx-auto max-w-2xl lg:max-w-none">
          {logos.map((logo, index) => (
            <a
              key={index}
              href={logo.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Imgix
                className="m-4 h-16 lg:m-6 lg:h-24"
                sizes="100vw"
                key={index}
                src={logo.image.sourceUrl}
                alt={logo.image.altText}
              />
            </a>
          ))}
        </div>
      </div>
    </section>
  )
}
