import React from "react"
import { Card } from "@elements"

export const WhatAreYouLookingFor = ({ global, cssClasses, htmlId }) => {
  return (
    <section
      id={htmlId}
      className={`module-what-are-you-looking-for pt-16 lg:pt-20 ${cssClasses}`}
    >
      <h2
        className={`text-4xl xl:text-5xl text-center font-light leading-tight px-4`}
      >
        {global.whatAreYouLookingForTitle}
      </h2>
      <div className="px-4 mt-32 bg-gradient-brand lg:mt-40">
        <div className="flex flex-wrap transform -translate-y-24 lg:-mx-2 lg:justify-center">
          {global.features &&
            global.features.map((card, index) => (
              <div
                key={index}
                className="w-full max-w-lg mx-auto mt-6 lg:w-1/3 lg:mt-0 lg:px-2 lg:mx-0 lg:max-w-md"
              >
                <Card
                  title={card.featureTitle}
                  image={card.image && card.image.sourceUrl}
                  altText={card.image && card.image.altText}
                  linkUrl={card.callToActionGroup.ctaLinkUrl}
                  content={card.featureTextContent}
                />
              </div>
            ))}
        </div>
      </div>
    </section>
  )
}
