import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { motion, AnimatePresence } from "framer-motion"
import { useAppState } from "../Layout"
import { useScrollFreeze } from "@hooks"
import { Link } from "@elements"

export const SlidingMenu = () => {
  const { isMenuOpen } = useAppState()
  return <AnimatePresence>{isMenuOpen && <Navigation />}</AnimatePresence>
}

const Navigation = () => {
  const data = useStaticQuery(graphql`
    query {
      ...MainNav
      site {
        siteMetadata {
          API_URL
        }
      }
    }
  `)

  const { toggleMenu } = useAppState()
  useScrollFreeze()
  return (
    <motion.div
      key="MobileNavigation"
      initial={{ x: `100%`, opacity: 0 }}
      animate={{ x: `0%`, opacity: 1 }}
      exit={{ x: `100%`, opacity: 0 }}
      transition={{ ease: "easeInOut" }}
      className="fixed inset-0 z-40 bg-white bg-opacity-75"
    >
      <nav className="absolute inset-y-0 right-0 z-50 w-full max-w-lg bg-white shadow-xl">
        <div className="flex justify-end">
          <button
            onClick={toggleMenu}
            className="px-5 py-4 text-6xl leading-none text-royal-400"
          >
            &times;
          </button>
        </div>
        <div className="flex flex-col justify-center divide-y divide-gray-100">
          {data.mainNav.menuItems.edges.map(({ node }) => (
            <Link
              key={node.url}
              to={`${node.url.replace(data.site.siteMetadata.API_URL, "")}`}
              className="relative py-4 text-xl text-center cursor-pointer hover:text-blue-400"
              activeClassName="font-bold text-teal-600 bg-teal-100"
            >
              {node.label}
            </Link>
          ))}
        </div>
      </nav>
    </motion.div>
  )
}
