import React from "react"
import PropTypes from "prop-types"

function Tab({ label, onClick, isActive }) {
  const handleClick = () => {
    onClick(label)
  }

  return (
    <li
      className={`flex flex-1 justify-center items-center relative z-0 transform scale-90 bg-white 
    ${
      isActive
        ? "scale-100 border-t border-l border-r border-gray-100 shadow-2xl text-teal-500 z-10"
        : "-mx-3"
    }
    `}
      style={{
        bottom: `${isActive ? "-2px" : "-4px"}`,
      }}
    >
      <button
        className={`p-1 sm:p-4 bg-white uppercase font-medium w-full text-sm sm:text-lg`}
        aria-label={label}
        style={{ outline: "none" }}
        onClick={handleClick}
      >
        {label}
      </button>
    </li>
  )
}

Tab.propTypes = {
  isActive: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default Tab
