import React from "react"

export const Legal = () => {
  return (
    <div>
      <p className="max-w-xl flex-grow mt-10 text-xs text-gray-600 italic">
        Payments and insights reflect estimated principal and interest only and
        do not include taxes and insurance. Actual payment obligations may be
        higher.
      </p>
    </div>
  )
}

export default Legal
