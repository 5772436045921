import React from "react"
import { Imgix } from "@elements"
import { Link } from "@elements"
import { YouTubeGetID } from "@utils"

// API Notes
//
//   "backgroundColor": "gradient",
//   "comboCardContent": null,
//   "callsToAction": [
//     {
//       "callToActionGroup": {
//         "ctaButtonType": "primary",
//         "ctaLinkUrl": "/loan-options/",
//         "ctaLinkText": "Learn More"
//       }
//     }
//   ],
//   "comboContent": "<p>Not sure what type of loan you’re looking for? Check out your options, or better yet contact us to discuss your goals.</p>\n",
//   "comboFlip": null,
//   "comboHeading": "Loan Options",
//   "comboMediaType": "Image",
//   "comboStyle": "Fifty-fifty",
//   "comboImage": {
//     "sourceUrl": "https://rum.imgix.net/wp-content/uploads/2020/04/19085608/loan-options-cash.jpg"
//   }
//
//

export const Combo = ({
  cssClasses,
  backgroundColor: bgColor,
  comboCardContent,
  callsToAction,
  comboContent,
  comboFlip,
  comboHeading,
  comboMediaType,
  comboImage,
  comboYoutubeVideoFixed,
  htmlId,
}) => {
  const backgroundColor =
    bgColor === "white"
      ? `bg-white`
      : bgColor === "light gray"
      ? `bg-offwhite`
      : bgColor === "gradient"
      ? `bg-gradient-brand text-white`
      : `bg-transparent`
  
    let mediaElement;
    let hasMedia = false;
    let hasVideo = false;
    if ( comboMediaType === "Image" && comboImage ) {
        hasMedia = true;
        mediaElement = (
            <div className="relative w-full h-full overflow-hidden pb-2/3">
              <Imgix
                sizes="(min-width: 1024px) 50vw, 100vw"
                src={comboImage.sourceUrl}
                alt={comboImage.altText}
                className="absolute object-cover w-full h-full"
              />
            </div>
        )
    } else if ( comboMediaType === "Video") {
        if ( comboYoutubeVideoFixed ) {
            hasMedia = true;
            hasVideo = true;
            let ytId = YouTubeGetID(comboYoutubeVideoFixed)
            let ytUrl = "https://www.youtube.com/embed/"+ytId
            mediaElement = (
                <iframe width="560" height="315" src={ytUrl} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            )
        } else {
            mediaElement = (
                <div></div>
            )
        }
    }
      
  return (
    <section
      id={htmlId}
      className={`module-combo ${backgroundColor} ${
        comboCardContent ? `py-20` : `py-0`
      } ${cssClasses}`}
    >
      <div className="flex flex-wrap">
        <div
          className={`w-full lg:w-1/2 ${
            comboFlip || comboCardContent ? `lg:order-last` : ``
          } ${comboCardContent ? `lg:flex lg:items-center px-4` : `block`} ${hasVideo ? 'combo-video-container' : ''}`}
        >
          {hasMedia ? mediaElement : comboCardContent ? (
            <div className="w-full max-w-2xl p-10 mx-auto bg-white shadow-xl md:p-12 lg:mx-0">
              <div
                dangerouslySetInnerHTML={{ __html: comboCardContent }}
                className="combo-card"
              ></div>
            </div>
          ) : (
            <div className="w-full h-full bg-royal-400 pb-2/3">
              Something coming soon
            </div>
          )}
        </div>
        <div
          className={`w-full px-8 py-10 overflow-hidden lg:p-16 xl:p-20 lg:w-1/2 flex items-center justify-center ${
            comboFlip ? `lg:justify-end` : `lg:justify-start`
          }`}
        >
          <div className="max-w-2xl lg:max-w-lg">
            <h2
              className={`text-3xl md:text-4xl xl:text-5xl font-light font-display leading-tight ${
                bgColor === "gradient" ? `text-white` : `text-gray-400`
              }`}
            >
              {comboHeading}
            </h2>
            <div
              dangerouslySetInnerHTML={{ __html: comboContent }}
              className={`mt-6 wysiwyg ${
                bgColor === "gradient" ? `text-white` : `text-gray-200`
              }`}
            ></div>
            {callsToAction && (
              <div className="mt-10 text-center sm:text-left">
                {callsToAction.map((cta, index) => (
                  <Link
                    to={cta.callToActionGroup.ctaLinkUrl}
                    key={index}
                    className={`btn sm:ml-0 sm:mr-6 w-full sm:w-auto text-center mb-4 ${cta.callToActionGroup.ctaButtonType}`}
                  >
                    {cta.callToActionGroup.ctaLinkText}
                  </Link>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
