import LoanCalc from "loan-calc"
import isFinite from "lodash/isFinite"
import every from "lodash/every"

// Utility for formatting percentages
export const percentFormatter = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 3,
  maximumFractionDigits: 3,
})

// Utility for formatting money
export const moneyFormatter = new Intl.NumberFormat("en-US", {
  // style: "currency",
  // currency: "USD",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
})

export const calculateMonthlyPayment = (principle, loanTerm, interest) => {
  //   const strippedLoanTerm = +loanTerm.split(" ")[0]
  const p = principle
  //   const n = strippedLoanTerm * 12
  const n = loanTerm * 12
  const i = interest / 100 / 12
  return moneyFormatter.format(
    (p * i * Math.pow(1 + i, n)) / (Math.pow(1 + i, n) - 1)
  )
}

export const calculateMonthlyPaymentInt = (principle, loanTerm, interest) => {
  //   const strippedLoanTerm = +loanTerm.split(" ")[0]
  const p = principle
  //   const n = strippedLoanTerm * 12
  const n = loanTerm * 12
  const i = interest / 100 / 12
  return (p * i * Math.pow(1 + i, n)) / (Math.pow(1 + i, n) - 1)
}

export const calculateMonthlySavings = (
  currentMonthlyPayment,
  refiMonthlyPayment
) => {
  let current = parseFloat(currentMonthlyPayment)
  let refi = parseFloat(refiMonthlyPayment)
  let difference = current - refi
  if (difference > 0) {
    return `You will save $${moneyFormatter.format(difference)} every month`
  } else if (difference < 0) {
    return `You will spend $${-moneyFormatter.format(
      difference
    )} more every month`
  }
}

export const refiTotalInterestDifference = (
  currentPrincipal,
  currentYearsRemaining,
  currentInterestRate,
  cashOut,
  newInterestRate,
  newLoanTerm
) => {
  if (
    !isValidNumbers([
      currentPrincipal,
      currentYearsRemaining,
      currentInterestRate,
      cashOut,
      newInterestRate,
      newLoanTerm,
    ])
  ) {
    return 0
  }

  const currentTotalInterest = LoanCalc.totalInterest({
    amount: currentPrincipal,
    rate: currentInterestRate,
    termMonths: currentYearsRemaining * 12,
  })

  const refiTotalInterest = LoanCalc.totalInterest({
    amount: currentPrincipal + cashOut,
    rate: newInterestRate,
    termMonths: newLoanTerm * 12,
  })

  const difference = currentTotalInterest - refiTotalInterest
  if (difference > 0) {
    return `You will save $${moneyFormatter.format(
      difference
    )} in interest over the loan's lifetime`
  } else if (difference <= 0) {
    return `You will spend $${moneyFormatter.format(
      Math.abs(difference)
    )} more on interest over the loan's lifetime`
  }
}

export function isValidNumbers(nums = []) {
  return every(nums, isFinite)
}

/**
 * Calculate the number of Payments that must be made to pay of a loan of a given amount (principal)
 * where interest annual percentage and payment is the monthly payment
 *
 * Rounds the value up
 * @param interest
 * @param principal
 * @param payment
 */
export function countPaymentsOnLoan(interest, principal, payment) {
  const i = interest / 100 / 12

  return Math.ceil(-Math.log(1 - (i * principal) / payment) / Math.log(1 + i))
}
