import React from "react"
import { AnimatePresence, motion } from "framer-motion"
import { useToggle } from "@hooks"

export const Accordion = ({
  accordionTitle = "Lorem Ipsum",
  accordionContent = "Lorem Ipsum",
}) => {
  const { isToggled, toggle } = useToggle(false)
  return (
    /* eslint-disable-next-line jsx-a11y/click-events-have-key-events */
    <article
      className="px-2 pb-5 duration-150 border-b border-gray-100 hover:border-purple-400 transitiopn"
      /* eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role */
      role="button"
      onClick={toggle}
    >
      <h2 className="flex items-center justify-between pt-4 text-2xl tracking-wide text-purple-400 capitalize">
        {accordionTitle}
        <span
          className={`transition duration-150 transform ${
            isToggled ? `rotate-90` : `-rotate-90`
          }`}
        >
          <svg
            className="w-4 h-4"
            viewBox="0 0 22 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className="stroke-current"
              d="M5 5L17.6484 17.6484L5 30.2969"
              strokeWidth="6"
              strokeLinecap="square"
            />
          </svg>
        </span>
      </h2>
      <AnimatePresence>
        {isToggled && (
          <motion.div
            className="overflow-hidden text-gray-200 md:pr-12"
            initial={{ height: 0 }}
            animate={{ height: "auto" }}
            exit={{ height: 0 }}
            transition={{ ease: "easeInOut" }}
          >
            <div
              className="my-3 wysiwyg"
              dangerouslySetInnerHTML={{ __html: accordionContent }}
            ></div>
          </motion.div>
        )}
      </AnimatePresence>
    </article>
  )
}
