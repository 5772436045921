import React from "react"

export const Wysiwyg = ({
  wysiwyg,
  wysiwygHeading,
  wysiwygSubheading,
  cssClasses,
  htmlId,
}) => {
  return (
    <section
      id={htmlId}
      className={`module-wysiwyg relative z-10 pt-32 pb-24 bg-white ${cssClasses}`}
    >
      <div className="container px-4">
        {wysiwygHeading && (
          <h2 className={`text-4xl xl:text-5xl text-center font-light`}>
            {wysiwygHeading}
          </h2>
        )}
        {wysiwygSubheading && <h3>{wysiwygSubheading}</h3>}
        {wysiwyg && (
          <div
            dangerouslySetInnerHTML={{ __html: wysiwyg }}
            className="wysiwyg"
          ></div>
        )}
      </div>
    </section>
  )
}
