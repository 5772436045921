import React from "react"
import { LoanRefinance } from "./LoanRefinance"
import { MortgagePayment } from "./MortgagePayment"
import { ExtraPayment } from "./ExtraPayment"
import Tabs from "@elements/Tabs"

export const AllCalculators = ({ cssClasses, htmlId }) => {
  return (
    <section
      id={htmlId}
      className={`module-calculators my-8 px-4 sm:px-12 ${cssClasses}`}
    >
      <div className="container">
        <Tabs>
          <div label="Refinance">
            <LoanRefinance />
          </div>
          <div label="New Home">
            <MortgagePayment />
          </div>
          <div label="Monthly Payments">
            <ExtraPayment />
          </div>
        </Tabs>
      </div>
    </section>
  )
}
