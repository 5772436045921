/**
 * Cleanest way to html entities decode
 * @param input
 * @returns {string}
 */
export function htmlDecode(input) {
  if (typeof window !== "undefined") {
    const doc = new DOMParser().parseFromString(input, "text/html")
    return doc.documentElement.textContent
  }
  return input
}

/**
 * Truncate strings
 * @param str = string to truncate
 * @param n = number of characters to truncate to
 * @param symbol = number of characters to truncate to
 * @returns {string}
 */
export const truncate = (str, n, symbol = " ...") => {
  return str.length > n ? str.substr(0, n - 1) + ` ${symbol}` : str
}

/**
 * Cleanest way to html entities decode
 * @param url
 * @returns {string}
 */
export const YouTubeGetID = url => {
  var ID = ""
  url = url
    .replace(/(>|<)/gi, "")
    .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)
  if (url[2] !== undefined) {
    ID = url[2].split(/[^0-9a-z_-]/i)
    ID = ID[0]
  } else {
    ID = url
  }
  return ID
}
