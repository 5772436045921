import React from "react"
import Tabs from "@elements/VerticalTabs"

export const TabGroup = ({ title, introContent, tabs, cssClasses, htmlId }) => {
  return (
    <section
      id={htmlId}
      className={`module-tab-group transform -translate-y-32 z-10 relative ${cssClasses}`}
    >
      <div className="container px-4">
        <div className="container my-12 shadow-2xl">
          <div className="bg-white p-12 md:p-16 lg:p-20">
            <h2 className="text-2xl font-light leading-none tracking-tight sm:text-3xl lg:text-4xl font-display mb-2">
              {title}
            </h2>
            <div
              className="lg:w-3/5 text-gray-300 lg:text-base mt-6 font-light opacity-100 mb-16"
              dangerouslySetInnerHTML={{ __html: introContent }}
            />
            <Tabs>
              {tabs.map(tab => {
                return (
                  <div key={tab.tabGroupTabTitle} label={tab.tabGroupTabTitle}>
                    <h3 className="text-2xl font-light leading-none tracking-tight sm:text-3xl lg:text-4xl font-display mb-8">
                      {tab.tabGroupTabContentTitle}
                    </h3>

                    <div
                      className="wysiwyg"
                      dangerouslySetInnerHTML={{
                        __html: tab.tabGroupTabContent,
                      }}
                    />
                  </div>
                )
              })}
            </Tabs>
          </div>
        </div>
      </div>
    </section>
  )
}
