import React from "react"
import { Imgix } from "@elements"
import ModalVideo from "react-modal-video"
import { useToggle } from "@hooks"
import { YouTubeGetID } from "@utils"

export const Billboard = ({
  cssClasses,
  billboardType,
  imageSrc,
  altText,
  billboardYoutubeVideo = "http://www.youtube.com/watch?v=Ab25nviakcw",
  htmlId,
}) => {
  const { isToggled, toggle } = useToggle(false)
  switch (billboardType) {
    case "image":
      return (
        <section
          id={htmlId}
          className={`module-billboard relative z-10 ${cssClasses}`}
        >
          <div className="relative overflow-hidden pb-3/6 md:pb-2/6">
            <Imgix
              sizes="100vw"
              className="absolute inset-0 object-cover w-full h-full"
              src={imageSrc}
              alt={altText}
            />
          </div>
        </section>
      )
    case "video":
      return (
        <>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
          <section
            id={htmlId}
            className={`module-billboard relative z-10 ${cssClasses}`}
            /* eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role */
            role="button"
            onClick={toggle}
          >
            <div className="relative overflow-hidden pb-3/6 md:pb-2/6">
              <Imgix
                sizes="100vw"
                className="absolute inset-0 object-cover w-full h-full"
                src={imageSrc}
              />
              <div className="absolute inset-0 z-30 flex items-center justify-center transition duration-150 bg-transparent hover:bg-blue-400 hover:bg-opacity-50">
                <svg
                  preserveAspectRatio="xMidYMid slice"
                  viewBox="0 0 200 200"
                  className="w-24 h-24 sm:w-32 xl:w-40 sm:h-32 xl:h-40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M198 100c0 54.124-43.876 98-98 98-54.1239 0-98-43.876-98-98C2 45.8761 45.8761 2 100 2c54.124 0 98 43.8761 98 98z"
                    stroke="#fff"
                    strokeWidth="4"
                  />
                  <path
                    d="M126.883 103.5l-41.0986 22.735c-2.666 1.475-5.9363-.453-5.9363-3.5V77.2646c0-3.0467 3.2703-4.9749 5.9363-3.5001l41.0986 22.7353c2.752 1.5223 2.752 5.4782 0 7.0002z"
                    stroke="#fff"
                    strokeWidth="4"
                  />
                </svg>
              </div>
            </div>
          </section>
          <ModalVideo
            channel="youtube"
            isOpen={isToggled}
            videoId={YouTubeGetID(billboardYoutubeVideo)}
            onClose={toggle}
          />
        </>
      )
    default:
      return (
        <div>{`Billboard type of '${billboardType}' does not seem to exist`}</div>
      )
  }
}
