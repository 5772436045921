import React from "react"
import { Link } from "@elements"
import Logo from "@images/logos/logo.png"
import {
  Prenavigation,
  MobileNavigation,
  DesktopNavigation,
} from "./Navigation"

export const Header = () => {
  return (
    <header className="relative z-40 bg-gray-100">
      <Prenavigation />
      <div className="flex justify-between bg-white shadow-lg z-10">
        <Link
          to="/"
          className="w-56 max-w-xs px-4 py-6 sm:w-64 lg:w-64 xl:ml-16"
        >
          <img src={Logo} alt="Royal United Mortgage Logo" />
        </Link>
        <DesktopNavigation />
        <MobileNavigation />
      </div>
      <div className="w-full z-0 relative shadow-lg sm:hidden">
        <a
          className="flex items-center justify-center w-full py-4 font-semibold transition duration-150 bg-blue-400 hover:bg-blue-500 text-white"
          href="https://myloan.royalunited.com/?borrowerportal&siteid=4239967203"
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg
            viewBox="0 0 36 40"
            className="h-5 stroke-current"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M34 38V34C34 29.5817 30.4183 26 26 26H10C5.58172 26 2 29.5817 2 34V38"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M18 18C22.4183 18 26 14.4183 26 10C26 5.58172 22.4183 2 18 2C13.5817 2 10 5.58172 10 10C10 14.4183 13.5817 18 18 18Z"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span className="ml-4">My Account</span>
        </a>
      </div>
    </header>
  )
}
