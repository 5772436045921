import React from "react"
import { Form, Link } from "@elements"
import { useGravityForms } from "@hooks"

export const HeroLandingPage = ({
  cssClasses,
  ctaLinkText,
  ctaLinkUrl,
  headline,
  heroContent,
  formId,
  formTitle,
  queryParams,
}) => {
  const form = useGravityForms(formId)
  const formTitleHTML = formTitle || "Contact Us."
  
  return (
    <section
      className={`module-hero flex flex-wrap bg-gradient-brand ${cssClasses}`}
    >
      <div className="w-full lg:w-1/2 lg:flex lg:items-center lg:justify-center">
        <div className="px-4 pt-8 pb-10 pb-16 text-white lg:py-20 xl:pl-20 min-h-hero lg:flex lg:justify-center lg:flex-col lg:items-start">
          <div className="sm:max-w-xl sm:mx-auto lg:max-w-md xl:max-w-2xl xl:mx-0">
            <h1
              className="text-4xl font-medium leading-none sm:text-5xl xl:text-6xl font-display"
              dangerouslySetInnerHTML={{ __html: headline }}
            ></h1>
            <div
              dangerouslySetInnerHTML={{ __html: heroContent }}
              className="max-w-xs mt-5 sm:max-w-md xl:max-w-lg wysiwyg-hero"
            />
            {ctaLinkUrl && (
              <Link to={ctaLinkUrl} className="mt-8 btn primary">
                {ctaLinkText}
              </Link>
            )}
          </div>
        </div>
      </div>
      <div className="w-full lg:w-1/2">
        <div className="px-4 pb-10 pb-16 lg:py-20 xl:pr-20">
          <div className=" p-12 pl-8 bg-white sm:max-w-xl sm:mx-auto lg:max-w-md xl:max-w-2xl xl:mx-0">
            <h2 className="mb-8 text-2xl font-light leading-tight tracking-tight sm:text-3xl lg:text-5xl font-display" dangerouslySetInnerHTML={{ __html: formTitleHTML }}>
            </h2>
            <Form
              form={form}
              queryParams={queryParams}
              submitButton={({ isSubmitting }) => (
                <div className="flex justify-end">
                  <button
                    type="submit"
                    className={`mt-4 btn primary ${
                      isSubmitting ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                    disabled={isSubmitting}
                  >
                    Send Message
                  </button>
                </div>
              )}
            />
          </div>
        </div>
      </div>
    </section>
  )
}
