import React, { Component } from "react"
import PropTypes from "prop-types"
import { motion } from "framer-motion"

import Tab from "./Tab"

class Tabs extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Array).isRequired,
  }
  constructor(props) {
    super(props)
    this.state = {
      activeTab: this.props.children[0].props.label,
    }
  }
  onClickTabItem = tab => {
    this.setState({ activeTab: tab })
  }
  render() {
    const {
      onClickTabItem,
      props: { children },
      state: { activeTab },
    } = this
    return (
      <div className="flex flex-wrap">
        <div className="flex-initial mr-4">
          <ol className="flex flex-col w-full">
            {children.map(child => {
              const { label } = child.props
              return (
                <Tab
                  isActive={activeTab === label}
                  key={label}
                  label={label}
                  onClick={onClickTabItem}
                />
              )
            })}
          </ol>
        </div>
        <div className="relative z-10 py-6 lg:pl-8 bg-white w-full lg:w-auto lg:flex-1">
          {children.map(child => {
            if (child.props.label !== activeTab) return undefined
            return (
              <motion.div
                key={child.props.label}
                initial="hidden"
                animate="visible"
                transition={{ ease: "easeInOut" }}
                variants={{
                  hidden: { opacity: 0 },
                  visible: { opacity: 1 },
                }}
              >
                {child.props.children}
              </motion.div>
            )
          })}
        </div>
      </div>
    )
  }
}

export default Tabs
