import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { VerticalBarSeries, XYPlot, XAxis } from "react-vis"

import {
  calculateMonthlySavings,
  refiTotalInterestDifference,
  calculateMonthlyPaymentInt,
  moneyFormatter,
} from "./calculations"
import Legal from "./Legal"

export const LoanRefinance = () => {
  const { register, handleSubmit, errors, watch } = useForm()
  const [showInsights, setShowInsights] = useState(false)
  const [data, setData] = useState({
    totalInterestDifference: 0,
    refiMonthlyPayment: 0,
    monthlySavings: "",
    current_principal: 0,
    current_years_remaining: 0,
    current_interest: 0,
    current_monthly_payment: 0,
    new_interest: 0,
    new_cash_out: 0,
    new_loan_term: 0,
  })

  watch()

  const onSubmit = data => {
    setShowInsights(false)

    setData(doCalculations(data))

    setShowInsights(true)
  }

  return (
    <div className="flex items-start justify-center">
      <div className="flex-grow p-4 lg:p-12 mx-auto bg-white">
        <h2 className="text-3xl font-medium leading-tight text-royal-400">
          Loan Refinance
        </h2>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-wrap">
            <div className="w-full lg:w-2/3">
              <h3 className="mt-5 font-bold">Current Mortgage</h3>
              {/* Current Mortgage Information */}
              <div className="flex flex-wrap mt-2">
                {/* Current Principal Balance */}
                <label className="block w-full md:w-1/2">
                  <span className="text-sm leading-tight text-gray-300">
                    Current Principal Balance
                  </span>
                  <input
                    name="current_principal"
                    type="number"
                    placeholder="$225,000"
                    step="1"
                    className="block w-full sm:w-1/2 px-2 mt-2 font-semibold border-4 rounded-none form-input border-royal-400 text-royal-400"
                    ref={register({ required: true, min: 1, max: 725000 })}
                  />
                  {errors[`current_principal`] &&
                    errors[`current_principal`].type === "required" && (
                      <span className="text-red-600">
                        This field is required!
                      </span>
                    )}
                  {errors[`current_principal`] &&
                    errors[`current_principal`].type === "min" && (
                      <span className="text-red-600">
                        Principal Balance must be positive!
                      </span>
                    )}
                  {errors[`current_principal`] &&
                    errors[`current_principal`].type === "max" && (
                      <span className="text-red-600">
                        Principal Balance is too big!
                      </span>
                    )}
                </label>

                {/* # of Years Remaining */}
                <label className="block w-full md:w-1/2">
                  <span className="text-sm leading-tight text-gray-300">
                    Years Remaining
                  </span>
                  <input
                    name="current_years_remaining"
                    type="number"
                    placeholder="13 Years"
                    className="block w-full sm:w-1/2 px-2 mt-2 font-semibold border-4 rounded-none form-input border-royal-400 text-royal-400"
                    ref={register({ required: true, min: 1 })}
                  />
                  {errors[`current_years_remaining`] &&
                    errors[`current_years_remaining`].type === "required" && (
                      <span className="text-red-600">
                        This field is required!
                      </span>
                    )}
                  {errors[`current_years_remaining`] &&
                    errors[`current_years_remaining`].type === "min" && (
                      <span className="text-red-600">
                        Years must be positive!
                      </span>
                    )}
                </label>

                {/* Current Interest Rate */}
                <label className="block w-full mt-4 md:w-1/2">
                  <span className="text-sm leading-tight text-gray-300">
                    Interest Rate
                  </span>
                  <input
                    name="current_interest"
                    type="number"
                    placeholder="4.500%"
                    className="block w-full sm:w-1/2 px-2 mt-2 font-semibold border-4 rounded-none form-input border-royal-400 text-royal-400"
                    step=".125"
                    ref={register({ required: true, min: 0 })}
                  />
                  {errors[`current_interest`] &&
                    errors[`current_interest`].type === "required" && (
                      <span className="text-red-600">
                        This field is required!
                      </span>
                    )}
                  {errors[`current_interest`] &&
                    errors[`current_interest`].type === "min" && (
                      <span className="text-red-600">
                        The interest must be positive!
                      </span>
                    )}
                </label>

                {/* Current monthly P&I Payment */}
                <label className="block w-full mt-4 md:w-1/2">
                  <span className="text-sm leading-tight text-gray-300">
                    Monthly Payment (P&amp;I)
                  </span>
                  <input
                    name="current_monthly_payment"
                    type="number"
                    placeholder="$701"
                    className="block w-full sm:w-1/2 px-2 mt-2 font-semibold border-4 rounded-none form-input border-royal-400 text-royal-400"
                    ref={register({ required: true, min: 0 })}
                  />
                  {errors[`current_monthly_payment`] &&
                    errors[`current_monthly_payment`].type === "required" && (
                      <span className="text-red-600">
                        This field is required!
                      </span>
                    )}
                  {errors[`current_monthly_payment`] &&
                    errors[`current_monthly_payment`].type === "min" && (
                      <span className="text-red-600">
                        The interest must be positive!
                      </span>
                    )}
                </label>
              </div>

              {/* New Mortgage Information */}
              <h3 className="mt-8 font-bold">New Mortgage</h3>
              <div className="flex flex-wrap">
                {/* New interest rate: */}
                <label className="block w-full mt-2 md:w-1/2">
                  <span className="text-sm leading-tight text-gray-300">
                    Interest Rate
                  </span>
                  <input
                    name="new_interest"
                    type="number"
                    placeholder="3.250%"
                    className="block w-full sm:w-1/2 px-2 mt-2 font-semibold border-4 rounded-none form-input border-royal-400 text-royal-400"
                    step=".125"
                    ref={register({ required: true, min: 0 })}
                  />
                  {errors[`new_interest`] &&
                    errors[`new_interest`].type === "required" && (
                      <span className="text-red-600">
                        This field is required!
                      </span>
                    )}
                  {errors[`new_interest`] &&
                    errors[`new_interest`].type === "min" && (
                      <span className="text-red-600">
                        The interest must be positive!
                      </span>
                    )}
                </label>

                {/* Desired cash out: */}
                <label className="block w-full mt-2 md:w-1/2">
                  <span className="text-sm leading-tight text-gray-300">
                    Desired Cash Out
                  </span>
                  <input
                    name="new_cash_out"
                    type="number"
                    placeholder="$10,000"
                    step="1"
                    className="block w-full sm:w-1/2 px-2 mt-2 font-semibold border-4 rounded-none form-input border-royal-400 text-royal-400"
                    ref={register({ min: 0 })}
                  />
                  {errors[`new_cash_out`] &&
                    errors[`new_cash_out`].type === "min" && (
                      <span className="text-red-600">
                        The cash out can't be negative!
                      </span>
                    )}
                </label>

                {/* New Loan Term: */}
                <label className="block w-full mt-4 md:w-1/2">
                  <span className="text-sm leading-tight text-gray-300">
                    Loan Term
                  </span>
                  <input
                    name="new_loan_term"
                    type="number"
                    placeholder="30 Years"
                    className="block w-full sm:w-1/2 px-2 mt-2 font-semibold border-4 rounded-none form-input border-royal-400 text-royal-400"
                    ref={register({ required: true, min: 1 })}
                  />
                  {errors[`new_loan_term`] &&
                    errors[`new_loan_term`].type === "required" && (
                      <span className="text-red-600">
                        This field is required!
                      </span>
                    )}
                  {errors[`new_loan_term`] &&
                    errors[`new_loan_term`].type === "min" && (
                      <span className="text-red-600">
                        The loan term must be greater than 0!
                      </span>
                    )}
                </label>
                <div className="mt-12">
                  <button
                    className="inline-block px-10 py-3 font-normal text-white bg-teal-500 hover:bg-teal-600"
                    type="submit"
                  >
                    {showInsights ? `Recalculate` : `See Insights`}
                  </button>
                </div>
              </div>
            </div>

            <div className="w-full mt-6 lg:w-1/3">
              <div className="h-full p-8 bg-offwhite">
                <h3 className="font-bold">
                  {showInsights
                    ? `Your Insights`
                    : `Your calculation will appear here.`}
                </h3>

                <ul className={`mb-4 ${showInsights ? `ml-4 list-disc` : ``}`}>
                  {/* New Monthly Payment */}
                  <li>
                    {showInsights ? (
                      `Your new monthly payment will be $${moneyFormatter.format(
                        data.refiMonthlyPayment
                      )}`
                    ) : (
                      <span className="inline-block w-full h-3 bg-gray-200" />
                    )}
                  </li>

                  {/* Monthly savings */}
                  <li>
                    {showInsights ? (
                      data.monthlySavings
                    ) : (
                      <span className="inline-block w-full h-3 bg-gray-200" />
                    )}
                  </li>

                  {/* Interest cost savings over lifetime of loan */}
                  <li>
                    {showInsights ? (
                      data.totalInterestDifference
                    ) : (
                      <span className="inline-block w-full h-3 bg-gray-200" />
                    )}
                  </li>
                </ul>
                <style>{`.rv-xy-plot__axis__tick__text { font-size: 12px }`}</style>
                {showInsights ? (
                  <div className={`-ml-8`}>
                    <XYPlot
                      margin={{
                        bottom: 0,
                        top: 100,
                      }}
                      xType="ordinal"
                      height={200}
                      width={350}
                    >
                      <XAxis orientation="top" />
                      <VerticalBarSeries
                        color="#3D7DCA"
                        data={[
                          {
                            x: `Current Payment: $${moneyFormatter.format(
                              data.current_monthly_payment
                            )}`,
                            y: data.current_monthly_payment,
                          },
                          {
                            x: `New Payment: $${moneyFormatter.format(
                              data.refiMonthlyPayment
                            )}`,
                            y: data.refiMonthlyPayment,
                          },
                        ]}
                      />
                    </XYPlot>
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <Legal />
        </form>
      </div>
    </div>
  )
}

function doCalculations(data) {
  const current_principal = parseInt(data.current_principal)
  const current_years_remaining = parseInt(data.current_years_remaining)
  const current_interest = parseFloat(data.current_interest)
  const current_monthly_payment = parseInt(data.current_monthly_payment)
  const new_interest = parseFloat(data.new_interest)
  const new_cash_out = parseInt(data.new_cash_out || "0")
  const new_loan_term = parseInt(data.new_loan_term)

  const calculatedCurrentMonthlyPayment = calculateMonthlyPaymentInt(
    current_principal,
    current_years_remaining,
    current_interest
  )

  const totalInterestDifference = refiTotalInterestDifference(
    current_principal,
    current_years_remaining,
    current_interest,
    new_cash_out || 0,
    new_interest,
    new_loan_term
  )

  const refiMonthlyPayment = calculateMonthlyPaymentInt(
    current_principal + new_cash_out,
    new_loan_term,
    new_interest
  )

  return {
    calculatedCurrentMonthlyPayment,
    totalInterestDifference,
    refiMonthlyPayment,
    monthlySavings: calculateMonthlySavings(
      current_monthly_payment,
      refiMonthlyPayment
    ),
    current_principal,
    current_years_remaining,
    current_interest,
    current_monthly_payment,
    new_interest,
    new_cash_out,
    new_loan_term,
  }
}
