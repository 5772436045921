import React from "react"

export const Glossary = ({ glossarySection, cssClasses, htmlId }) => {
  return (
    <>
      <section id={htmlId} className="px-4 pt-10">
        <div className="container">
          <small className="block">Jump to:</small>
          <nav className="flex flex-wrap">
            {glossarySection.map((item, index) => {
              const count = glossarySection.length
              return (
                <span
                  className="inline-block mt-2 pr-2 text-lg font-semibold"
                  key={index}
                >
                  <a href={`#${item.glossaryDesignationLabel}`}>
                    <span className="underline transition duration-150  hover:text-gray-200">
                      {item.glossaryDesignationLabel}
                    </span>
                    {index < count - 1 ? (
                      <span className="text-gray-200">&nbsp;&bull;</span>
                    ) : (
                      ``
                    )}
                  </a>
                </span>
              )
            })}
          </nav>
        </div>
      </section>
      <section className={`px-4 pb-10 ${cssClasses}`}>
        <div className="container">
          {glossarySection.map(item => (
            <article
              key={item.glossaryDesignationLabel}
              className="py-8"
              id={item.glossaryDesignationLabel}
            >
              <h2 className="text-3xl font-light leading-tight text-gray-400 md:text-4xl xl:text-5xl font-display">
                {item.glossaryDesignationLabel}
              </h2>
              <div
                className="max-w-3xl glossary"
                dangerouslySetInnerHTML={{ __html: item.glossaryTermContent }}
              ></div>
            </article>
          ))}
        </div>
      </section>
    </>
  )
}
