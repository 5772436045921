import React from "react"
import { Imgix } from "@elements"
import { Testimonial } from "./Testimonial"
import { useIncrement } from "@hooks"

export const Testimonials = ({
  cssClasses,
  testimonials = [],
  title,
  backgroundImage,
  altText,
  backgroundColor: bgColor,
  testimonialsImagePlacement,
  htmlId,
}) => {
  const testimonialCount = testimonials.length
  const [testimonialIndex, { incLoop, decLoop }] = useIncrement({
    maxValue: testimonialCount,
    minValue: 0,
    step: 1,
  })
  let nextTestimonialIndex =
    testimonialIndex + 1 > testimonialCount - 1 ? 0 : testimonialIndex + 1
  const backgroundColor =
    bgColor === "Light Gray"
      ? "bg-offwhite"
      : bgColor === "White"
      ? "bg-white"
      : "bg-offwhite"
  return (
    <section
      id={htmlId}
      className={`module-testimonials relative z-30 pb-8 -mb-12 lg:pb-0 overflow-x-hidden ${backgroundColor} ${cssClasses}`}
    >
      <div className="flex flex-wrap">
        <div className="w-full lg:w-1/2">
          <div className="relative h-56 overflow-hidden shadow-lg lg:shadow-2xl lg:pb-full xl:pb-3/4">
            <Imgix
              sizes="(min-width: 1024px) 50vw, 100vw"
              src={backgroundImage}
              alt={altText}
              className="absolute inset-0 object-cover w-full h-full"
            />
          </div>
        </div>
        <div
          className={`relative w-full p-8 overflow-hidden lg:p-16 xl:p-20 lg:w-1/2 ${
            testimonialsImagePlacement === "Right"
              ? "lg:order-first"
              : "order-none"
          }`}
        >
          <div className="">
            <h2 className="text-3xl font-light leading-tight tracking-tight text-center text-gray-400 lg:text-left sm:text-4xl lg:text-5xl font-display lg:max-w-xl">
              {title}
            </h2>
          </div>
        </div>
      </div>
      <div className="relative top-0 flex items-center max-w-2xl mx-auto lg:absolute lg:transform lg:translate-y-64 lg:inset-x-0">
        <button
          aria-label="View Previous Testimonial"
          onClick={decLoop}
          className="relative z-20 flex-shrink-0 w-10 h-10 font-bold text-white transition duration-150 transform translate-x-1/2 bg-teal-500 hover:bg-teal-600"
        >
          &#x3c;
        </button>
        <Testimonial
          content={testimonials[testimonialIndex].content}
          slug={testimonials[testimonialIndex].slug}
          reviewerName={testimonials[testimonialIndex].author}
          reviewerPhoto={testimonials[testimonialIndex].imgUrl}
          animationDirection={testimonialsImagePlacement}
        />
        <div
          className={`absolute hidden transform md:block ${
            testimonialsImagePlacement === "Right"
              ? "-translate-x-full"
              : "translate-x-full "
          }`}
        >
          <Testimonial
            content={testimonials[nextTestimonialIndex].content}
            slug={testimonials[testimonialIndex].slug}
            reviewerName={testimonials[nextTestimonialIndex].author}
            reviewerPhoto={testimonials[testimonialIndex].imgUrl}
            opaqueContent={true}
          />
        </div>
        <button
          aria-label="View Next Testimonial"
          onClick={incLoop}
          className="relative z-20 flex-shrink-0 w-10 h-10 font-bold text-white transition duration-150 transform -translate-x-1/2 bg-blue-400 hover:bg-blue-500"
        >
          &#x3e;
        </button>
      </div>
    </section>
  )
}
