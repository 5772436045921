import React from "react"
import { Card } from "@elements"

export const Features = ({ introduction, features, cssClasses, htmlId }) => {
  return (
    <section
      id={htmlId}
      className={`module-what-are-you-looking-for pt-16 lg:pt-20 ${cssClasses}`}
    >
      <div className="max-w-3xl mx-auto">
        <h2
          className={`text-4xl xl:text-5xl text-center font-light leading-tight px-4`}
        >
          {introduction.introHeadline}
        </h2>
        <h3>{introduction.introSubheadline}</h3>
        <div
          className="mt-4 wysiwyg text-center"
          dangerouslySetInnerHTML={{ __html: introduction.introContent }}
        ></div>
      </div>
      <div className="px-4 mt-32 bg-gradient-brand lg:mt-40">
        <div className="flex flex-wrap transform -translate-y-24 lg:-mx-2 lg:justify-center">
          {features &&
            features.map((card, index) => (
              <div
                key={index}
                className="w-full max-w-lg mx-auto mt-6 lg:w-1/3 lg:mt-0 lg:px-2 lg:mx-0 lg:max-w-md"
              >
                <Card
                  title={card.featureTitle}
                  image={card.image && card.image.sourceUrl}
                  linkUrl={card.callToActionGroup.ctaLinkUrl}
                  content={card.featureTextContent}
                />
              </div>
            ))}
        </div>
      </div>
    </section>
  )
}
