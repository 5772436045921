import React from "react"
import { Imgix } from "@elements"

const SocialProof = ({ heroSocialProof }) => {
  return (
    <div className="flex flex-wrap items-center justify-center py-4 xl:justify-between xl:px-20">
      {heroSocialProof.map((item, index) => (
        <div
          className="flex items-center justify-center h-full p-4 xl:py-4 xl:px-0"
          key={index}
        >
          <a
            href={item.socialProofUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="social-proof-url"
          >
            <Imgix
              sizes="auto"
              src={item.socialProofLogo.sourceUrl}
              alt={item.socialProofLogo.altText}
              className="w-auto h-12 md:h-16 lg:h-12 xl:h-16"
            />
          </a>
        </div>
      ))}
    </div>
  )
}

export default SocialProof
