import React from "react"

export const Code = ({
  code,
  cssClasses,
  htmlId,
}) => {
  return (
    <section
      id={htmlId}
      className={`module-code ${cssClasses}`}
      dangerouslySetInnerHTML={{__html: code}}
    >
    </section>
  )
}
