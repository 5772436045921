import React from "react"
import { useAppState } from "../Layout"

export const MobileNavigation = () => {
  const { toggleMenu } = useAppState()
  return (
    <nav className="flex items-center mr-4 lg:hidden">
      <button
        aria-label="Open and Close Menu"
        onClick={toggleMenu}
        className="w-10 h-10 text-royal-400 md:h-12 md:w-12"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          className="w-full h-full"
        >
          <path
            className="fill-current"
            d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
          ></path>
        </svg>
      </button>
    </nav>
  )
}
