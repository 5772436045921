import React, { Component } from "react"
import PropTypes from "prop-types"

import Tab from "./Tab"

class Tabs extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Array).isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      activeTab: this.props.children[0].props.label,
    }
  }

  onClickTabItem = tab => {
    this.setState({ activeTab: tab })
  }

  render() {
    const {
      onClickTabItem,
      props: { children },
      state: { activeTab },
    } = this

    return (
      <div className={`w-full`}>
        <div
          className={`border-b border-gray-100 `}
          style={{ paddingBottom: "1px" }}
        >
          <ol className={`flex w-full lg:w-2/3`}>
            {children.map(child => {
              const { label } = child.props

              return (
                <Tab
                  isActive={activeTab === label}
                  key={label}
                  label={label}
                  onClick={onClickTabItem}
                />
              )
            })}
          </ol>
        </div>

        <div
          className={`p-4 border-l border-r border-gray-100 bg-white z-10 relative`}
        >
          {children.map(child => {
            if (child.props.label !== activeTab) return undefined
            return child.props.children
          })}
        </div>
      </div>
    )
  }
}

export default Tabs
