import React from "react"
import ImgixOriginal from "react-imgix"

export const Imgix = props => {
  if (!props.src) {
    return null
  }

  const { className, alt, ...rest } = props

  return (
    <ImgixOriginal
      className={`lazyload ${className}`}
      attributeConfig={{
        src: "data-src",
        srcSet: "data-srcset",
        sizes: "data-sizes",
      }}
      htmlAttributes={{
        // low quality image placeholder
        src: `${props.src}?blur=200&px=16&auto=format&w=400`,
        alt: alt,
      }}
      {...rest}
    />
  )
}
export default Imgix
