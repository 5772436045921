import { graphql, useStaticQuery } from "gatsby"
import shuffle from "lodash/shuffle"
import trimHtml from "trim-html"
import { useMemo } from "react"
import take from "lodash/take"

export const useTestimonials = amount => {
  const { wpgraphql } = useStaticQuery(
    graphql`
      query {
        wpgraphql {
          ...Testimonials
        }
      }
    `
  )

  const shuffled = useMemo(() => {
    return shuffle(wpgraphql.testimonials.edges)
  }, [wpgraphql.testimonials.edges])

  return take(shuffled, amount).map(({ node }) => {
    const { ctTestimonialFields, featuredImage, content, ...rest } = node
    return {
      ...rest,
      // trimHtml doesnt cut off words and adds '...' to the end
      // replace(/(<([^>]+)>)/ig strips html
      content: trimHtml(content, {
        limit: 250,
        preserveTags: true,
        suffix: "...",
      }).html.replace(/(<([^>]+)>)/gi, ""),
      author: ctTestimonialFields.author,
      imgUrl: featuredImage && featuredImage.sourceUrl,
    }
  })
}
