import React, { useState } from "react"

import { moneyFormatter, percentFormatter } from "./calculations"
import { motion } from "framer-motion"
import { Link } from "@elements"

export const calculateMonthlyPayment = (p, n, i) => {
  return moneyFormatter.format(
    (p * i * Math.pow(1 + i, n)) / (Math.pow(1 + i, n) - 1)
  )
}

export const MortgagePayment = () => {
  let monthlyPayment //monthly mortgage payment
  const [principal, setPrincipal] = useState(225000)
  const [interest, setInterest] = useState(3.75)
  const [loanTerm, setLoanTerm] = useState("30 years")
  const [clickCount, setClickCount] = useState(0)

  let strippedLoanTerm = +loanTerm.split(" ")[0]

  //set monthly mortgage payment
  monthlyPayment = calculateMonthlyPayment(
    principal,
    strippedLoanTerm * 12,
    interest / 100 / 12
  )

  return (
    <div className="flex items-start justify-center">
      {/* Start Calculator with inputs */}
      <div className="flex-grow p-12 mx-auto bg-white">
        <h2 className="text-3xl font-medium leading-tight text-royal-400">
          Mortgage Payment Calculator
        </h2>
        <div className="flex flex-wrap -mx-8">
          <div className="w-full px-8 sm:w-1/2">
            <form action="" className="mt-5">
              <label className="block">
                <span className="text-sm leading-tight text-gray-300">
                  Home Loan Amount <br />
                  <strong className="inline-block text-4xl font-medium text-royal-400">
                    {" "}
                    <span className="inline-block text-lg font-normal transform -translate-y-2">
                      $
                    </span>
                    {moneyFormatter.format(principal)}
                  </strong>
                </span>
                <input
                  className="block w-full p-0 mt-2 border-none form-input"
                  placeholder="$225,000"
                  type="range"
                  min="25000"
                  max="725000"
                  step="1"
                  value={principal}
                  onChange={e => setPrincipal(e.target.value)}
                  onClick={() => setClickCount(clickCount => clickCount + 1)}
                />
              </label>
              <label className="block mt-4">
                <span className="text-sm leading-tight text-gray-300">
                  Interest Rate <br />
                  <strong className="inline-block text-4xl font-medium text-royal-400">
                    {percentFormatter.format(interest)}
                    <span className="inline-block text-lg font-normal transform translate-x-1 -translate-y-2">
                      %
                    </span>
                  </strong>
                </span>
                <input
                  type="range"
                  min="2.5"
                  max="6.5"
                  step=".125"
                  className="block w-full p-0 mt-2 border-none form-input"
                  placeholder="4.125%"
                  value={interest}
                  onChange={e => setInterest(e.target.value)}
                  onClick={() => setClickCount(clickCount => clickCount + 1)}
                />
              </label>
              <label className="block mt-4">
                <span className="text-sm text-gray-300">Loan Duration</span>
                {/* The requested functionality is onChange */}
                {/* eslint-disable-next-line jsx-a11y/no-onchange */}
                <select
                  className="block w-1/2 mt-2 font-semibold border-4 rounded-none form-select border-royal-400 text-royal-400"
                  defaultValue={loanTerm}
                  onChange={e => setLoanTerm(e.target.value)}
                  onClick={() => setClickCount(clickCount => clickCount + 1)}
                >
                  <option>10 years</option>
                  <option>15 years</option>
                  <option>20 years</option>
                  <option>25 years</option>
                  <option>30 years</option>
                </select>
              </label>
            </form>
            <div className="sm:hidden">
              <p className="flex-grow mx-auto mt-10 text-xs italic text-gray-600">
                This Calculator is for demonstrational purposes only and does
                not reflect any rate or payment you may qualify for.
              </p>
            </div>
          </div>
          <div className="order-first w-full px-8 sm:w-1/2 sm:order-none">
            <div className="mt-8 text-sm leading-tight text-gray-300 sm:mt-5">
              Estimated Monthly Payment
              <br />
              <strong className="inline-block text-6xl font-medium text-teal">
                {" "}
                <span className="inline-block text-2xl font-normal transform -translate-y-4">
                  $
                </span>
                <span className="text-blue-400">{monthlyPayment}</span>
              </strong>
            </div>
            {clickCount >= 2 ? (
              <motion.div
                className="mt-4 sm:mt-10"
                initial={{ y: 25, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                // transition={{ ease: "easeIn", duration: 0.25 }}
              >
                <Link
                  className="inline-block px-10 py-3 font-normal text-white bg-teal-500 hover:bg-teal-600"
                  to={"/contact-us/"}
                >
                  Get Started
                </Link>
              </motion.div>
            ) : null}
            <div className="hidden sm:block">
              <p className="flex-grow mx-auto mt-10 text-xs italic text-gray-600">
                This Calculator is for demonstrational purposes only and does
                not reflect any rate or payment you may qualify for.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
