import React from "react"
import PropTypes from "prop-types"

function Tab({ label, onClick, isActive }) {
  const handleClick = () => {
    onClick(label)
  }
  const buttonClasses = isActive
    ? "tabgroup__tab--active text-white bg-gradient-tabs"
    : "text-teal-500"
  return (
    <li
      className={`border-b border-gray-100 flex items-stretch relative`}
      style={{
        bottom: `${isActive ? "-2px" : "-4px"}`,
      }}
    >
      <button
        aria-label={label}
        onClick={handleClick}
        className={`tabgroup__tab p-4 w-full text-left font-semibold uppercase items-stretch outline-none ${buttonClasses}`}
      >
        {label}
      </button>
    </li>
  )
}

Tab.propTypes = {
  isActive: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default Tab
