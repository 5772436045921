import React from "react"
import { Imgix } from "@elements"

export const HowWeWork = ({ global, cssClasses, htmlId }) => {
  return (
    <section
      id={htmlId}
      className={`module-how-we-work py-10 lg:py-16 ${cssClasses}`}
    >
      <div className="container px-4">
        <h2 className="text-5xl font-light tracking-tight text-center text-gray-400 lg:text-6xl">
          {global.howWeWorkTitle}
        </h2>
        {/* grid start */}
        <div className="flex flex-wrap max-w-sm mx-auto mt-4 sm:max-w-md lg:max-w-none">
          {global.workBlocks.map((block, index) => (
            <div className="w-full mt-4 text-center lg:w-1/3" key={index}>
              {/* card start */}
              <div className="h-full px-8 py-12 overflow-hidden transition duration-150 transform how-we-work-card hover:bg-white hover:shadow-xl lg:px-10 lg:py-16 hover:-translate-y-1">
                <div className="relative">
                  <div className="flex items-center h-24">
                    {block.workBlockIcon && (
                      <Imgix
                        sizes={block.workBlockIcon.sizes}
                        src={block.workBlockIcon.sourceUrl}
                        alt={block.workBlockIcon.altText}
                        className="h-24 mx-auto transition duration-150"
                      />
                    )}
                    <span className="flex items-center justify-center w-full text-6xl font-medium tracking-tighter text-gray-100 transition duration-150 transform scale-150 font-display">
                      {index + 1}
                    </span>
                  </div>
                  <h3 className="mt-8 text-xl font-medium text-gray-400 transition duration-150 font-display">
                    {block.workBlockTitle}
                  </h3>
                  <p className="mt-4 text-sm leading-loose tracking-wide text-gray-200 transition duration-150">
                    {block.workBlockContent}
                  </p>
                </div>
              </div>
              {/* card end */}
            </div>
          ))}
        </div>
        {/* grid end */}
      </div>
    </section>
  )
}
