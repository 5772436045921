import React from "react"
import { graphql, useStaticQuery } from "gatsby"

export const Prenavigation = () => {
  const { wpgraphql } = useStaticQuery(
    graphql`
      query {
        wpgraphql {
          ...OptionsContactSocial
        }
      }
    `
  )

  const contactInfo =
    wpgraphql.optionsContactSocial.options_global.contactInformation

  return (
    <nav className="items-center justify-end hidden text-white sm:flex bg-royal-400">
      <a
        className="flex items-center pr-8 font-semibold transition duration-150 hover:text-blue-100"
        href={`tel:${contactInfo.phoneNumber.replace(/\(|\)|-|\s/g, "")}`}
      >
        <svg
          className="h-5 stroke-current"
          viewBox="0 0 41 42"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M38.9988 29.7566V35.3376C39.0031 36.3853 38.5655 37.3862 37.7935 38.0944C37.0215 38.8027 35.9867 39.1526 34.9433 39.0583C29.2187 38.4363 23.7198 36.4801 18.8885 33.3471C14.3936 30.4908 10.5826 26.6799 7.72638 22.185C4.58235 17.3317 2.62576 11.8061 2.01511 6.05574C1.92112 5.01557 2.26863 3.98378 2.97276 3.21243C3.67689 2.44107 4.6728 2.00117 5.71721 2.00018H11.2983C13.1658 1.9818 14.7576 3.35074 15.0189 5.19998C15.2545 6.98604 15.6914 8.73972 16.3212 10.4276C16.8323 11.7872 16.5054 13.32 15.484 14.3529L13.1214 16.7155C15.7697 21.373 19.626 25.2293 24.2835 27.8776L26.6461 25.515C27.679 24.4936 29.2118 24.1667 30.5715 24.6778C32.2593 25.3077 34.013 25.7445 35.799 25.9801C37.6697 26.244 39.0457 27.8679 38.9988 29.7566Z"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <span className="ml-4">{contactInfo.phoneNumber}</span>
      </a>
      <a
        className="flex items-center justify-center w-48 py-4 font-semibold transition duration-150 bg-blue-400 hover:bg-blue-500"
        href="https://myloan.royalunited.com/?borrowerportal&siteid=4239967203"
        target="_blank"
        rel="noopener noreferrer"
      >
        <svg
          viewBox="0 0 36 40"
          className="h-5 stroke-current"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M34 38V34C34 29.5817 30.4183 26 26 26H10C5.58172 26 2 29.5817 2 34V38"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18 18C22.4183 18 26 14.4183 26 10C26 5.58172 22.4183 2 18 2C13.5817 2 10 5.58172 10 10C10 14.4183 13.5817 18 18 18Z"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <span className="ml-4">My Account</span>
      </a>
    </nav>
  )
}
