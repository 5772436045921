import React from "react"
import { Imgix } from "@elements"
import { Link } from "@elements"
import SocialProof from "./SocialProof"

export const Hero = ({
  cssClasses,
  alt,
  ctaLinkText,
  ctaLinkUrl,
  headline,
  heroContent,
  heroSocialProof,
  showSocialProof,
  src,
  htmlId,
}) => {
  return (
    <section id={htmlId} className={`module-hero flex flex-wrap ${cssClasses}`}>
      <div className="w-full lg:w-7/12">
        <div className="px-4 pt-8 pb-10 pb-16 text-white lg:py-20 xl:pl-20 bg-gradient-brand min-h-hero lg:flex lg:justify-center lg:flex-col lg:items-start">
          <div className="sm:max-w-xl sm:mx-auto lg:max-w-md xl:max-w-2xl xl:mx-0">
            <h1
              className="text-4xl font-medium leading-none sm:text-5xl lg:text-4xl xl:text-6xl font-display"
              dangerouslySetInnerHTML={{ __html: headline }}
            ></h1>
            <p className="max-w-xs mt-5 sm:max-w-md xl:max-w-lg xl:text-lg">
              {heroContent}
            </p>
            {ctaLinkUrl && (
              <Link to={ctaLinkUrl} className="mt-8 btn primary text-center">
                {ctaLinkText}
              </Link>
            )}
          </div>
        </div>
        {showSocialProof === "Yes" && (
          <SocialProof heroSocialProof={heroSocialProof} />
        )}
      </div>
      <div className="order-first w-full lg:order-none lg:w-5/12">
        <div className="relative h-48 overflow-hidden shadow-2xl sm:h-56 md:h-64 lg:h-full">
          <Imgix
            alt={alt}
            sizes="(min-width: 1024px) 41.6vw, 100vw"
            className="absolute inset-0 object-cover w-full h-full"
            src={src}
          />
        </div>
      </div>
    </section>
  )
}
