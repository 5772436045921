import React from "react"
import { Link } from "@elements"

export const HeroText = ({
  cssClasses,
  ctaButtonType,
  ctaLinkText,
  ctaLinkUrl,
  headline,
  heroContent,
  htmlId,
}) => {
  return (
    <section
      id={htmlId}
      className={`module-hero-text py-10 pt-20 px-12 ${cssClasses}`}
    >
      <div className="container">
        <h1 className="text-4xl font-medium leading-none sm:text-5xl lg:text-4xl xl:text-6xl font-display">
          {headline}
        </h1>
        {heroContent && (
          <p className="max-w-xs mt-5 sm:max-w-md xl:max-w-lg xl:text-lg">
            {heroContent}
          </p>
        )}
        {ctaLinkText && (
          <div className="mt-8">
            <Link to={ctaLinkUrl} className={`btn ${ctaButtonType}`}>
              {ctaLinkText}
            </Link>
          </div>
        )}
      </div>
    </section>
  )
}
