import React from "react"
import { truncate, htmlDecode } from "@utils"
import { Imgix } from "@elements"
import { Link } from "@elements"

export const Card = ({ title, content, image, altText, linkUrl }) => {
  const trimmedTitle = htmlDecode(truncate(title, 45))
  return (
    <Link to={linkUrl} className="block h-full" aria-label={`View ${title}`}>
      <article className="w-full h-full overflow-hidden transition duration-150 transform shadow-xl hover:-translate-y-1">
        <div className="relative overflow-hidden bg-white pb-1/2">
          <Imgix
            sizes="(min-width: 1024px) 41.6vw, 100vw"
            className="absolute top-0 left-0 object-cover w-full h-full"
            src={image}
            alt={altText}
          />
        </div>
        <div className="h-full p-10 bg-white xl:px-12">
          <h3 className="flex items-center justify-between text-xl font-medium text-gray-400 font-display capitalize">
            {trimmedTitle}
            <span>
              <svg
                className="w-5 h-5"
                viewBox="0 0 22 35"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 5L17.6484 17.6484L5 30.2969"
                  stroke="#3E77B2"
                  strokeWidth="6"
                  strokeLinecap="square"
                />
              </svg>
            </span>
          </h3>
          <p className="mt-5 text-sm leading-relaxed tracking-wide text-gray-200">
            {content && truncate(content, 150)}
          </p>
        </div>
      </article>
    </Link>
  )
}
