import React, { useState } from "react"

import Legal from "./Legal"
import { useForm } from "react-hook-form"
import {
  countPaymentsOnLoan,
  moneyFormatter,
  calculateMonthlyPaymentInt,
} from "./calculations"
import LoanCalc from "loan-calc"

export const ExtraPayment = () => {
  const { register, handleSubmit, errors, watch } = useForm({})

  const [showInsights, setShowInsights] = useState(false)
  const [data, setData] = useState({
    minMonthlyPayment: 0,
    impactInMonths: 0,
    totalInterestWithoutExtraPayment: 0,
    totalInterestWithExtraPayment: 0,
    impactInInterestPaid: 0,
    extra_monthly_payment: 0,
  })

  watch()

  const onSubmit = data => {
    setShowInsights(false)

    setData(doCalculations(data))

    setShowInsights(true)
  }

  return (
    <div className="flex items-start justify-center">
      {/* Start Calculator with inputs */}
      <div className="bg-white p-12 mx-auto flex-grow">
        <h2 className="font-medium text-3xl text-brand-royal-blue leading-tight">
          Payments
        </h2>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="flex flex-wrap">
            <div className="w-full lg:w-2/3">
              <h3 className="mt-5 font-bold">Current Mortgage</h3>

              {/* Mortgage Information */}
              <div className="mt-2 flex flex-wrap">
                {/* Principal Balance */}
                <label className="block w-full md:w-1/2">
                  <span className="text-sm">Principal Balance</span>
                  <input
                    name="principal"
                    type="number"
                    placeholder="$189,000"
                    step="1"
                    className="block w-full sm:w-1/2 px-2 mt-2 font-semibold border-4 rounded-none form-input border-royal-400 text-royal-400"
                    ref={register({ required: true, min: 1, max: 1000000 })}
                  />
                  {errors[`principal`] &&
                    errors[`principal`].type === "required" && (
                      <span className="text-red-600">
                        This field is required!
                      </span>
                    )}
                  {errors[`principal`] &&
                    errors[`principal`].type === "min" && (
                      <span className="text-red-600">
                        Principal Balance must be positive!
                      </span>
                    )}
                  {errors[`principal`] &&
                    errors[`principal`].type === "max" && (
                      <span className="text-red-600">
                        Principal Balance is too big!
                      </span>
                    )}
                </label>

                {/* Interest Rate */}
                <label className="block w-full md:w-1/2">
                  <span className="text-sm">Interest Rate</span>
                  <input
                    name="interest_rate"
                    type="number"
                    placeholder="3.250%"
                    className="block w-full sm:w-1/2 px-2 mt-2 font-semibold border-4 rounded-none form-input border-royal-400 text-royal-400"
                    step=".125"
                    ref={register({ required: true, min: 0 })}
                  />
                  {errors[`interest_rate`] &&
                    errors[`interest_rate`].type === "required" && (
                      <span className="text-red-600">
                        This field is required!
                      </span>
                    )}
                  {errors[`interest_rate`] &&
                    errors[`interest_rate`].type === "min" && (
                      <span className="text-red-600">
                        The interest must be positive!
                      </span>
                    )}
                </label>

                {/* Loan Term */}
                <label className="block w-full md:w-1/2 mt-4">
                  <span className="text-sm">Loan Term</span>
                  <input
                    name="loan_term"
                    type="number"
                    placeholder="30 Years"
                    className="block w-full sm:w-1/2 px-2 mt-2 font-semibold border-4 rounded-none form-input border-royal-400 text-royal-400"
                    ref={register({ required: true, min: 1, max: 32 })}
                  />
                  {errors[`loan_term`] &&
                    errors[`loan_term`].type === "required" && (
                      <span className="text-red-600">
                        This field is required!
                      </span>
                    )}
                  {errors[`loan_term`] &&
                    errors[`loan_term`].type === "min" && (
                      <span className="text-red-600">
                        Years must be positive!
                      </span>
                    )}
                  {errors[`loan_term`] &&
                    errors[`loan_term`].type === "max" && (
                      <span className="text-red-600">
                        The loan term is too long!
                      </span>
                    )}
                </label>

                {/* Extra Monthly Payment */}
                <label className="block w-full md:w-1/2 mt-4">
                  <span className="text-sm">Extra Monthly Payment</span>
                  <input
                    name="extra_monthly_payment"
                    type="number"
                    placeholder="$75"
                    className="block w-full sm:w-1/2 px-2 mt-2 font-semibold border-4 rounded-none form-input border-royal-400 text-royal-400"
                    ref={register({
                      required: true,
                      min: 0,
                    })}
                  />
                  {errors[`extra_monthly_payment`] &&
                    errors[`extra_monthly_payment`].type === "required" && (
                      <span className="text-red-600">
                        This field is required!
                      </span>
                    )}
                  {errors[`extra_monthly_payment`] &&
                    errors[`extra_monthly_payment`].type === "min" && (
                      <span className="text-red-600">
                        The interest must be positive!
                      </span>
                    )}
                </label>
              </div>

              <div className="mt-8">
                <button
                  className="inline-block bg-teal-500 hover:bg-teal-600 text-white font-normal px-10 py-3"
                  type="submit"
                >
                  {showInsights ? `Recalculate` : `See Insights`}
                </button>
              </div>
            </div>

            <div className="mt-6 w-full lg:w-1/3">
              <div className="p-8 bg-offwhite h-full">
                <h3 className="font-bold">
                  {showInsights
                    ? `Your Insights`
                    : `Your calculation will appear here.`}
                </h3>
                <ul className={`mb-4 ${showInsights ? `ml-4 list-disc` : ``}`}>
                  {/* Minimum Monthly Payment */}
                  <li>
                    {showInsights ? (
                      `Your estimated monthly payment will be $${moneyFormatter.format(
                        data.minMonthlyPayment
                      )}`
                    ) : (
                      <span className="h-3 w-full inline-block bg-gray-200" />
                    )}
                  </li>

                  {/* ================= hide when extra payment is 0 ========================= */}

                  {data.extra_monthly_payment !== 0 && (
                    <>
                      {/* Minimum Monthly Payment + Extra Monthly Payment */}
                      <li>
                        {showInsights ? (
                          `Your estimated monthly payment plus extra payment will be $${moneyFormatter.format(
                            data.totalMonthlyPayment
                          )}`
                        ) : (
                          <span className="h-3 w-full inline-block bg-gray-200" />
                        )}
                      </li>

                      {/* Impact of extra money on time in months */}
                      <li>
                        {showInsights ? (
                          `Paying $${moneyFormatter.format(
                            data.extra_monthly_payment
                          )} extra each month will pay off your loan ${
                            data.impactInMonths
                          } months faster`
                        ) : (
                          <span className="h-3 w-full inline-block bg-gray-200" />
                        )}
                      </li>

                      {/* Interest cost savings over lifetime of loan */}
                      <li>
                        {showInsights ? (
                          `Your extra payment will save you $${moneyFormatter.format(
                            data.impactInInterestPaid
                          )} in interest`
                        ) : (
                          <span className="h-3 w-full inline-block bg-gray-200" />
                        )}
                      </li>
                    </>
                  )}

                  {/* ========================================== */}

                  {/* Total interest paid over the lifetime of the loan */}
                  <li>
                    {showInsights ? (
                      `You will pay $${moneyFormatter.format(
                        data.totalInterestWithExtraPayment
                      )} in interest over the loans full term`
                    ) : (
                      <span className="h-3 w-full inline-block bg-gray-200" />
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <Legal />
        </form>
      </div>
      {/* End Calculator */}
    </div>
  )
}

function doCalculations(data) {
  const extra_monthly_payment = parseInt(data.extra_monthly_payment)

  // The amount the user must pay to pay off the loan
  // given the principal, interest rate, loan term
  const minMonthlyPayment = calculateMonthlyPaymentInt(
    parseInt(data.principal),
    parseInt(data.loan_term),
    parseFloat(data.interest_rate)
  )

  const totalMonthlyPayment =
    minMonthlyPayment + parseInt(data.extra_monthly_payment)

  // Given $X dollars as an extra monthly payment against the principal
  // then the impact that has on the loan term in months
  const impactInMonths = Math.abs(
    countPaymentsOnLoan(
      parseFloat(data.interest_rate),
      parseInt(data.principal),
      minMonthlyPayment
    ) -
      countPaymentsOnLoan(
        parseFloat(data.interest_rate),
        parseInt(data.principal),
        minMonthlyPayment + parseInt(data.extra_monthly_payment)
      )
  )

  const totalInterestWithoutExtraPayment = LoanCalc.totalInterest({
    amount: parseInt(data.principal),
    rate: parseFloat(data.interest_rate),
    termMonths: parseInt(data.loan_term) * 12,
  })

  const totalInterestWithExtraPayment = LoanCalc.totalInterest({
    amount: parseInt(data.principal),
    rate: parseFloat(data.interest_rate),
    termMonths: countPaymentsOnLoan(
      parseFloat(data.interest_rate),
      parseInt(data.principal),
      minMonthlyPayment + parseInt(data.extra_monthly_payment)
    ),
  })

  // Given $X dollars as an extra monthly payment against the principal
  // the impact that has on the total interest paid over the loan term
  // ie: total interest without extra $ - total interest with extra $
  const impactInInterestPaid =
    totalInterestWithoutExtraPayment - totalInterestWithExtraPayment

  return {
    minMonthlyPayment,
    totalMonthlyPayment,
    impactInMonths,
    totalInterestWithoutExtraPayment,
    totalInterestWithExtraPayment,
    impactInInterestPaid,
    extra_monthly_payment,
  }
}
