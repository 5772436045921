import React from "react"
import { Accordion } from "@elements"

export const Accordions = ({
  accordionModuleTitle,
  accordions,
  cssClasses,
  htmlId,
}) => {
  return (
    <section
      id={htmlId}
      className={`module-accordion py-16 pb-20 bg-white lg:pb-24 lg:py-20 ${cssClasses}`}
    >
      <div className="container px-4">
        <h2 className="pl-2 text-4xl font-light text-gray-400 font-display">
          {accordionModuleTitle}
        </h2>
        <div className="mt-8">
          {accordions &&
            accordions.map((accordion, index) => (
              <Accordion
                key={index}
                accordionTitle={accordion.accordionTitle}
                accordionContent={accordion.accordionContent}
              />
            ))}
        </div>
      </div>
    </section>
  )
}
