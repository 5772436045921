import React from "react"
import { Link } from "@elements"

export const CenteredContentCard = ({
  cssClasses,
  centeredContentCardTitle,
  centeredContentCardContent,
  ctaButtonType,
  ctaLinkUrl,
  ctaLinkText,
  htmlId,
}) => {
  return (
    <section
      id={htmlId}
      className={`module-centered-content relative z-20 py-10 -mb-12 text-center lg:pt-20 sm:-mb-16 lg:-mb-20 pointer-events-none ${cssClasses}`}
    >
      <div className="px-4 pointer-events-auto">
        <div className="max-w-4xl px-4 py-10 mx-auto bg-white shadow-xl md:py-12 lg:py-24">
          <h2 className="text-3xl font-light leading-tight md:text-4xl xl:text-5xl font-display">
            {centeredContentCardTitle}
          </h2>
          {centeredContentCardContent && (
            <div
              className="max-w-3xl mx-auto mt-6 wysiwyg text-gray-200"
              dangerouslySetInnerHTML={{ __html: centeredContentCardContent }}
            ></div>
          )}
          <div className="mt-10">
            {ctaLinkText && (
              <Link
                to={ctaLinkUrl}
                className={`btn w-full sm:w-auto ${ctaButtonType}`}
              >
                {ctaLinkText}
              </Link>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
